import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

function PrivateRoute() {
    const token = localStorage.getItem('jwtToken');
    if (token) {
        return <Outlet />;
    } else {
        return <Navigate to="/login" />;
    }
}

export default PrivateRoute;
