import React, { useContext, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';  
import { UserProvider } from './contexts/UserContext'; 
import { MembershipProvider, MembershipContext } from './contexts/MembershipContext';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DelayedFallback from './components/DelayedFallback'; // Ajusta la ruta si es necesario

import membresias from './data/membresias.json';

// Componentes de página
const CreatePasswordPage = React.lazy(() => import('./pages/CreatePasswordPage'));
const NoDisponibleDemoPage = React.lazy(() => import('./pages/NoDisponibleDemoPage'));
const DemoPage = React.lazy(() => import('./pages/DemoPage'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const MigrationPage = React.lazy(() => import('./pages/MigrationPage'));
const LogoutPage = React.lazy(() => import('./pages/LogoutPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const BasePage = React.lazy(() => import('./pages/BasePage'));
const NoticiasPage = React.lazy(() => import('./pages/NoticiasPage'));
const PerfilesProyectosPage = React.lazy(() => import('./pages/PerfilesProyectosPage'));
const PerfilProyectoPage  = React.lazy(() => import('./pages/PerfilProyectoPage'));
const KioskoPage = React.lazy(() => import('./pages/KioskoPage'));
const KioskoReadPage = React.lazy(() => import('./pages/KioskoReadPage'));
const KioskoSeccionesPage = React.lazy(() => import('./pages/KioskoSeccionesPage'));
const EbookPage = React.lazy(() => import('./pages/EbookPage'));
const EbookCategoriesPage = React.lazy(() => import('./pages/EbookCategoriesPage'));
const DescargasPage = React.lazy(() => import('./pages/DescargasPage'));
const EstadosfinancierospdfPage = React.lazy(() => import('./pages/EstadosfinancierospdfPage'));
const ContenidoPage = React.lazy(() => import('./pages/ContenidoPage'));
const SoportePage = React.lazy(() => import('./pages/SoportePage'));

function MainComponent() {
    const { selectedMembership } = useContext(MembershipContext);

    if (selectedMembership) {
        const selectedAlias = membresias.membresias.find(m => m.nombre === selectedMembership).alias;

        document.body.classList = "";  // Elimina todas las clases
        document.body.classList.add(selectedAlias);
        document.body.classList.add('header-fixed');
        document.body.classList.add('header-tablet-and-mobile-fixed');
        
    }

    return (
        <Router>
            <Suspense fallback={<DelayedFallback delay={1000} fallback={<div>Loading...</div>} />}>
                <Routes>
                    <Route path="/demo" element={<DemoPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/migration" element={<MigrationPage />} />
                    <Route path="/create-password" element={<PrivateRoute />}>
                        <Route index element={<CreatePasswordPage />} />
                    </Route>
                    <Route path="/no-disponible-demo" element={<PrivateRoute />}>
                        <Route index element={<NoDisponibleDemoPage />} />
                    </Route>
                    <Route path="/dashboard" element={<PrivateRoute />}>
                        <Route index element={<DashboardPage />} />
                    </Route>
                    <Route path="/noticias" element={<PrivateRoute />}>
                        <Route index element={<NoticiasPage />} />
                    </Route>
                    <Route path="/perfiles-proyectos" element={<PrivateRoute />}>
                        <Route index element={<PerfilesProyectosPage />} />
                    </Route>
                    <Route path="/perfil/:id" element={<PrivateRoute />}>
                        <Route index element={<PerfilProyectoPage />} />
                    </Route>
                    <Route path="/descargas" element={<PrivateRoute />}>
                        <Route index element={<DescargasPage />} />
                    </Route>
                    <Route path="/estadosfinancierospdf" element={<PrivateRoute />}>
                        <Route index element={<EstadosfinancierospdfPage />} />
                    </Route>
                    <Route path="/kiosko" element={<PrivateRoute />}>
                        <Route index element={<KioskoPage />} />
                    </Route>
                    <Route path="/kiosko-secciones/:id" element={<PrivateRoute />}>
                        <Route index element={<KioskoSeccionesPage />} />
                    </Route>
                    <Route path="/kiosko-read/:id" element={<PrivateRoute />}>
                        <Route index element={<KioskoReadPage />} />
                    </Route>
                    <Route path="/ebook/:id" element={<PrivateRoute />}>
                        <Route index element={<EbookPage />} />
                    </Route>
                    <Route path="/ebook-categorias/:categoria" element={<PrivateRoute />}>
                        <Route index element={<EbookCategoriesPage />} />
                    </Route>
                    <Route path="/contenido/:id" element={<PrivateRoute />}>
                        <Route index element={<ContenidoPage />} />
                    </Route>
                    <Route path="/base/:table" element={<PrivateRoute />}>
                        <Route index element={<BasePage />} />
                    </Route>
                    <Route path="/soporte" element={<PrivateRoute />}>
                        <Route index element={<SoportePage />} />
                    </Route>
                    <Route path="/logout" element={<PrivateRoute />}>
                        <Route index element={<LogoutPage />} />
                    </Route>
                    {/* ... otras rutas ... */}
                    <Route path="/" element={<LoginPage />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

function App() {
    return (
        <UserProvider>
            <MembershipProvider>
                <MainComponent />
                <ToastContainer />
            </MembershipProvider>
        </UserProvider>
    );
}

export default App;
